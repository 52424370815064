import React from "react";
import { Container, Navbar } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import Language from "../language/Language";
import "./Navmenu.css";

export default function Navmenu() {
  return (
    <div className="navmenu">
      <Navbar className="nav-style">
        <Container>
          <Navbar.Brand>
            <img
              alt="checkme"
              src={require("../../imgs/logo-red.png")}
              height="50"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <ul className="nav-icons d-flex justify-content-center my-0 px-0">
                <li className="mx-4">
                  <Language />
                </li>
              </ul>
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
