import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



export const getOrdersByMobile = createAsyncThunk(
  "users/getOrdersByMobile",
  async (info, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://hook.eu1.make.com/awvt5s79ls8mp2365ntiyx0m1l2u6kqu",
        {
          Customer: [
            {
              Phone: info.phoneNumber,
            },
          ],
          Search: [
            {
              SearchTable: true,
              Function: "Search",
              TableName: "CheckMEOrder",
              Byfield: "PhoneNumber",
              Status: "All",
              AllRecords: false,
            },
          ],
        }
      );
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateStatusOfOrder = createAsyncThunk(
  "users/updateStatusOfOrder",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        "https://hook.eu1.make.com/awvt5s79ls8mp2365ntiyx0m1l2u6kqu",
        {
          Customer: [
            {
              Phone: data.phone,
            },
          ],
          Search: [
            {
              SearchTable: false,
              Function: "Update",
              TableName: "CheckMEOrder",
              "Order ID": data.orderId,
              Status: data.status,
              Comments: data.comment,
              "Update Date": new Date(),
              AllRecords: false,
            },
          ],
        }
      );
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadResultFile = createAsyncThunk(
  "users/uploadResult",
  async (result, { rejectWithValue }) => {
    let formData = new FormData();
    formData.append("File", result.resultFile);
    formData.append("OrderID", result.orderId);
    try {
      const response = await axios.post(
        "https://hook.eu1.make.com/mzbpm2cxnp22muts6ss1skwwa7ff7vq5",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data; boundary=MyBoundary",
          },
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPatientInfo = createAsyncThunk(
  "users/getPatientInfo",
  async (contact, { rejectWithValue }) => {
    try {
      const respon = await axios.post(
        "https://hook.eu1.make.com/awvt5s79ls8mp2365ntiyx0m1l2u6kqu",
        {
          Customer: [{ Phone: contact.patientPhoneNumber }],
          Search: [
            {
              SearchTable: true,
              Function: "Search",
              TableName: "CheckMEPatient",
              Byfield: "PhoneNumber",
              Status: "All",
              AllRecords: false,
            },
          ],
        }
      );
      return respon;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getReportFile = createAsyncThunk(
  "users/getReportFile",
  async (info, { rejectWithValue }) => {
    try {
      const report = await axios.get(
        "https://hook.eu1.make.com/kt9ibl37aeeknw0zm5ld9t3sguge4l22",
        {
          params: {
            OrderID: info.id,
            phonenumber: info.phoneNumber,
          },
        }
      );
      return report;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



const initialState = {
  phoneNumber: "",
  orders: [],
  showTable: false,
  updateModal: {
    orderId: "",
    show: false,
    phoneNumber:""
  },
  uploadModal: {
    orderId: "",
    show: false,
  },
  reportModal: {
    orderId: "",
    phoneNumber: "",
    show: false,
    getMessage: "",
    fileLink: "",
  },
  status: "",
  comment: "",
  update_message: "",
  upload_message: "",
  patientContact: {
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    address: "",
    pState: "",
  },
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    handleStatusOrderId: (state, action) => {
      state.updateModal.orderId = action.payload.id;
      state.updateModal.phoneNumber = action.payload.phoneNumber;
      state.updateModal.show = true;
    },
    handleModalClose: (state, action) => {
      state.updateModal.show = false;
    },
    handleUploadResult: (state, action) => {
      state.uploadModal.orderId = action.payload.id;
      state.uploadModal.show = true;
    },
    handleUploadResultClose: (state, action) => {
      state.uploadModal.show = false;
    },
    handleGetReport: (state, action) => {
      state.reportModal.orderId = action.payload.id;
      state.reportModal.show = true;
    },
    handleGetReportClose: (state, action) => {
      state.reportModal.show = false;
      state.reportModal.getMessage = "";
      state.reportModal.fileLink = "";
    },
  },
  extraReducers: {
    [getOrdersByMobile.fulfilled]: (state, action) => {
      state.orders = action.payload.data;
      state.phoneNumber = action.meta.arg.phoneNumber
      state.showTable = true;
    },
    [updateStatusOfOrder.fulfilled]: (state, action) => {
      state.updateModal.show = false;
    },
    [uploadResultFile.fulfilled]: (state, action) => {
      state.uploadModal.show = false;
      // window.location.reload();
    },
    [getPatientInfo.fulfilled]: (state, action) => {
      state.patientContact.firstName = action.payload.data.FirstName;
      state.patientContact.lastName = action.payload.data.LastName;
      state.patientContact.email = action.payload.data.Email;
      state.patientContact.gender = action.payload.data.Gender;
      state.patientContact.address = action.payload.data.Address;
      state.patientContact.pState = action.payload.data.State;
    },
    [getReportFile.fulfilled]: (state, action) => {
      state.reportModal.show = true;
      state.reportModal.getMessage = "";
      state.reportModal.fileLink = action.payload.data["Report Download Link"];
    },
    [getReportFile.pending]: (state, action) => {
      state.reportModal.getMessage = "Please Wait ....";
    },

    [getReportFile.rejected]: (state, action) => {
      state.reportModal.show = true;
      state.reportModal.getMessage =
        action.payload.response.data["Report Download Link"];
    },
  },
});

export const {
  handleStatusOrderId,
  handleModalClose,
  handleUploadResult,
  handleUploadResultClose,
  handleGetReport,
  handleGetReportClose,
} = userSlice.actions;
export default userSlice.reducer;
