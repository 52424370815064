import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Order from "./components/order/Order";
import "bootstrap/dist/css/bootstrap.min.css";
import Language from "./components/language/Language";
import "./App.css";

export const languages = [
  {
    code: "en",
    name: "English",
    dir: "ltr",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
  },
];

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Order />}></Route>
        </Routes>
      </Router>
    </div>
  );
}


export default App;
