import React, { useState, useEffect } from "react";
import { Modal, Form, Button, InputGroup, FormControl } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { handleUploadResultClose } from "../../redux/userSlice";
import { uploadResultFile } from "../../redux/userSlice";
import { useTranslation } from "react-i18next";
import { BsUpload } from "react-icons/bs";
import "./Upload.css";

export default function Upload() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const orderID = useSelector((state) => state.users.uploadModal.orderId);
  const uploadModalShow = useSelector((state) => state.users.uploadModal.show);
  const [orderId, setOrderId] = useState("");
  useEffect(() => {
    setOrderId(orderID);
    setShow(uploadModalShow);
  });
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(handleUploadResultClose());
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const formik = useFormik({
    initialValues: {
      resultFile: "",
    },
    onSubmit: async (values) => {
      const base64File = await convertBase64(values.resultFile);
      dispatch(uploadResultFile({ resultFile: values.resultFile, orderId }));
    },
    validationSchema: yup.object().shape({
      resultFile: yup
        .mixed()
        .required(t("ReportReq"))
        .test(
          "format",
          t("Reporttype"),
          (value) =>
            !value || (value && ["application/pdf"].includes(value.type))
        ),
    }),
  });


  return (
    <div className="upload">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="search-title" closeButton>
          <Modal.Title className="search-title-text">{t("UploadResultFile")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="search-body">
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              <div className="d-grid">
                <Form.Label className="input-label">
                  {t("UploadResultFile")}
                </Form.Label>
                <Form.Label
                  htmlFor="resultFile"
                  className="btn btn-md btn-warning btn-block"
                >
                  <BsUpload />
                  <span className="mx-2">{t("Choosefile")}</span>
                </Form.Label>
              </div>

              <Form.Control
                type="file"
                className="input-text"
                id="resultFile"
                name="resultFile"
                onChange={(event) => {
                  formik.setFieldValue(
                    "resultFile",
                    event.currentTarget.files[0]
                  );
                }}
              />
              <Form.Text className="text-error">
                {formik.errors.resultFile && formik.errors.resultFile}
              </Form.Text>
            </Form.Group>
            <div className="d-grid">
              <Button variant="success" type="submit">
                {t("Save")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
