import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
  Modal,
  Table,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import Navmenu from "../navmenu/Navmenu";
import Allorders from "./allOrders/Allorders";
import OrdersMobile from "./ordersByMobile/OrdersMobile";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersByMobile, getPatientInfo } from "../../redux/userSlice";
import { useTranslation } from "react-i18next";
import "./Order.css";

export default function Order() {
  const [tableShow, setTableShow] = useState(false);
  const dispatch = useDispatch();
  
  const show = useSelector((state) => state.users.showTable);
  const [searchShow, setSearchShow] = useState(false);
  const searchHandleClose = () => setSearchShow(false);
  const searchHandleShow = () => setSearchShow(true);

  const [infoShow, setInfoShow] = useState(false);
  const infoHandleClose = () => setInfoShow(false);
  const infoHandleShow = () => setInfoShow(true);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [pState, setPState] = useState("");

  const FirstName = useSelector((state) => state.users.patientContact.firstName);
  const LastName = useSelector((state) => state.users.patientContact.lastName);
  const Email = useSelector((state) => state.users.patientContact.email);
  const Gender = useSelector((state) => state.users.patientContact.gender);
  const Address = useSelector((state) => state.users.patientContact.address);
  const State = useSelector((state) => state.users.patientContact.pState);


  useEffect(() => {
    setTableShow(show);
    setFirstName(FirstName);
    setLastName(LastName);
    setEmail(Email);
    setGender(Gender);
    setAddress(Address);
    setPState(State);
  });


  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    onSubmit: (values) => {
      dispatch(
        getOrdersByMobile({
          phoneNumber: values.phoneNumber,
        })
      );
    },
    validationSchema: yup.object().shape({
      phoneNumber: yup.string().required("Mobile number is required"),
    }),
  });


  const formikk = useFormik({
    initialValues: {
      patientPhoneNumber: "",
    },
    onSubmit: (values) => {
      dispatch(getPatientInfo({ patientPhoneNumber: values.patientPhoneNumber }));
    },
    validationSchema: yup.object().shape({
      patientPhoneNumber: yup
        .string()
        .required("Patient mobile number is required"),
    }),
  });

  const { t } = useTranslation();

  return (
    <div className="order">
      <Navmenu />
      <Container>
        <Row>
          <Col xs={6} className="mt-3">
            <div className="d-grid">
              <Button
                variant="danger"
                size="sm"
                onClick={() => {
                  searchHandleShow();
                }}
              >
                {t("SearchMobile")}
              </Button>
            </div>
          </Col>
          <Col xs={6} className="mt-3">
            <div className="d-grid">
              <Button
                variant="danger"
                size="sm"
                onClick={() => {
                  infoHandleShow();
                }}
              >
                {t("GetPatientInfo")}
              </Button>
            </div>
          </Col>
          <Col xs={12} className="mt-3">
            {tableShow ? (
              <>
                <OrdersMobile />
              </>
            ) : (
              <>
                <Allorders />
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Modal show={searchShow} onHide={searchHandleClose} centered>
        <Modal.Header className="search-title" closeButton>
          <Modal.Title className="search-title-text">
            {t("SearchMobile")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="search-body">
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              <InputGroup>
                <InputGroup.Text id="basic-addon1" className="phone-addon">
                  <img src={require("../../imgs/egypt.png")} alt="flag" />
                </InputGroup.Text>
                <FormControl
                  type="tel"
                  maxLength="11"
                  placeholder={t("EnterMobileNumber")}
                  className="input-text"
                  id="phoneNumber"
                  onChange={formik.handleChange}
                  value={formik.values.phoneNumber}
                />
              </InputGroup>
              <Form.Text className="text-error">
                {formik.errors.phoneNumber && formik.errors.phoneNumber}
              </Form.Text>
            </Form.Group>
            <div className="d-grid">
              <Button variant="success" type="submit">
                {t("Search")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>


      <Modal show={infoShow} onHide={infoHandleClose} centered>
        <Modal.Header className="search-title" closeButton>
          <Modal.Title className="search-title-text">
            {t("GetPatientInfo")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="search-body">
          <Form onSubmit={formikk.handleSubmit}>
            <Form.Group className="mb-3">
              <InputGroup>
                <InputGroup.Text id="basic-addon1" className="phone-addon">
                  <img src={require("../../imgs/egypt.png")} alt="flag" />
                </InputGroup.Text>
                <FormControl
                  type="tel"
                  maxLength="11"
                  placeholder={t("EnterMobileNumber")}
                  className="input-text"
                  id="patientPhoneNumber"
                  onChange={formikk.handleChange}
                  value={formikk.values.patientPhoneNumber}
                />
              </InputGroup>
              <Form.Text className="text-error">
                {formikk.errors.patientPhoneNumber &&
                  formikk.errors.patientPhoneNumber}
              </Form.Text>
            </Form.Group>
            <div className="d-grid">
              <Button variant="warning" type="submit">
                {t("ViewInfo")}
              </Button>
            </div>
          </Form>
          <div className="info-patient mt-3">
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td>{t("FirstName")}</td>
                  <td className="font-styl">{firstName}</td>
                </tr>
                <tr>
                  <td>{t("LastName")}</td>
                  <td className="font-styl">{lastName}</td>
                </tr>
                <tr>
                  <td>{t("Gender")}</td>
                  <td className="font-styl">{gender}</td>
                </tr>
                <tr>
                  <td>{t("Email")}</td>
                  <td className="font-styl">{email}</td>
                </tr>
                <tr>
                  <td>{t("State")}</td>
                  <td className="font-styl">{pState}</td>
                </tr>
                <tr>
                  <td>{t("Address")}</td>
                  <td className="font-styl">{address}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
