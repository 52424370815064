import React, { useState, useEffect } from "react";
import { Modal, Form, Button, FloatingLabel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { updateStatusOfOrder } from "../../redux/userSlice";
import { handleModalClose } from "../../redux/userSlice";
import { useTranslation } from "react-i18next";
import "./Update.css";

export default function Update() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    dispatch(handleModalClose());
  };
  const orderID = useSelector((state) => state.users.updateModal.orderId);
  const phoneNumber = useSelector(
    (state) => state.users.updateModal.phoneNumber
  );
  const modalShow = useSelector((state) => state.users.updateModal.show);
  const [orderId, setOrderId] = useState("");
  const [phone, setPhone] = useState("");
  useEffect(() => {
    setOrderId(orderID);
    setShow(modalShow);
    setPhone(phoneNumber);
  });
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      status: "",
      comment: "",
    },
    onSubmit: (values) => {
      console.log(values);
      dispatch(
        updateStatusOfOrder({
          status: values.status,
          comment: values.comment,
          orderId,
          phone,
        })
      );
    },
    validationSchema: yup.object().shape({
      status: yup.string().required(t("StatusReq")),
      comment: yup.string().required(t("CommentReq")),
    }),
  });

  return (
    <div className="update">
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className="search-title" closeButton>
          <Modal.Title className="search-title-text">
            {t("UpdateStatus")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="search-body">
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="input-label">{t("SelectStatus")}</Form.Label>
              <Form.Select
                id="status"
                className="input-text"
                size="sm"
                onChange={formik.handleChange}
                value={formik.values.status}
              >
                <option value="">{t("SelectStatus")}</option>
                <option value="New">{t("New")}</option>
                <option value="Received">{t("Received")}</option>
                <option value="Visited">{t("Visited")}</option>
                <option value="Delivered">{t("Delivered")}</option>
                <option value="Completed">{t("Completed")}</option>
                <option value="Cancelled">{t("Cancelled")}</option>
              </Form.Select>
              <Form.Text className="text-error">
                {formik.errors.status && formik.errors.status}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="input-label">{t("AddComment")}</Form.Label>
              <FloatingLabel>
                <Form.Control
                  as="textarea"
                  id="comment"
                  size="sm"
                  className="input-text"
                  onChange={formik.handleChange}
                  value={formik.values.comment}
                />
              </FloatingLabel>
              <Form.Text className="text-error">
                {formik.errors.comment && formik.errors.comment}
              </Form.Text>
            </Form.Group>
            <div className="d-grid">
              <Button variant="success" type="submit" size="sm">
                {t("Save")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
