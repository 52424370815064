import React, { useRef, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Badge,
  Popover,
  OverlayTrigger,
  ListGroup,
} from "react-bootstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useDispatch, useSelector } from "react-redux";
import { GiTestTubes } from "react-icons/gi";
import { FaFileDownload } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import { FaUpload } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { MdDoNotDisturbAlt } from "react-icons/md";
import Update from "../../update/Update";
import Upload from "../../upload/Upload";
import { handleUploadResult } from "../../../redux/userSlice";
import { handleStatusOrderId } from "../../../redux/userSlice";
import { useTranslation } from "react-i18next";
import "./OrdersMobile.css";

export default function OrdersMobile() {
  const gridRef = useRef();
  const { t } = useTranslation();
  const rowOrders = useSelector((state) => state.users.orders);
  const phone = useSelector((state) => state.users.phoneNumber);
  const [orders, setOrders] = useState([]);
  const [phoneNum, setPhoneNum] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setOrders(rowOrders);
    setPhoneNum(phone);
  });

  const handleStatus = ({ id }) => {
    dispatch(handleStatusOrderId({ id, phoneNumber: phoneNum }));
  };

  const handleUpload = ({ id }) => {
    dispatch(handleUploadResult({ id }));
  };

  const columnDefs = [
    {
      headerName: "Status",
      field: "OrderDetails.Status",
      minWidth: 200,
      filter:true,
      floatingFilter: true,
      cellRenderer: ({ data }) => {
        return (
          <div className="py-2">
            <h6 className="status-style">
              {data.OrderDetails.Status === "New" ? (
                <Badge pill bg="danger">
                  {t("New")}
                </Badge>
              ) : data.OrderDetails.Status === "Received" ? (
                <Badge pill bg="success">
                  {t("Received")}
                </Badge>
              ) : data.OrderDetails.Status === "Visited" ? (
                <Badge pill bg="warning">
                  {t("Visited")}
                </Badge>
              ) : data.OrderDetails.Status === "Delivered" ? (
                <Badge pill bg="primary">
                  {t("Delivered")}
                </Badge>
              ) : data.OrderDetails.Status === "Completed" ? (
                <Badge pill bg="dark">
                  {t("Completed")}
                </Badge>
              ) : (
                <Badge pill bg="dark">
                  Cancelled
                </Badge>
              )}
            </h6>
          </div>
        );
      },
    },
    {
      headerName: "Order ID",
      field: "OrderDetails.OrderID",
      minWidth: 200,
      filter:true,
      floatingFilter: true,
    },
    {
      headerName: "All Tests",
      field: "Tests",
      minWidth: 100,
      cellRenderer: ({ data }) => {
        return (
          <div>
            <OverlayTrigger
              placement="right"
              rootClose={true}
              rootCloseEvent="mousedown"
              overlay={
                <Popover id={`popover-positioned-right`}>
                  <Popover.Header as="h6" className="popover-title">
                    {t("PatientTest")}
                  </Popover.Header>
                  <Popover.Body className="popover-body">
                    <ListGroup as="ol" numbered>
                      {data.Tests.map((test) => {
                        return (
                          <ListGroup.Item
                            as="li"
                            className="items"
                            key={Math.random() * 1000}
                          >
                            {test.TestName}
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="info" size="sm">
                <GiTestTubes />
              </Button>
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      headerName: "Comments",
      field: "OrderDetails.Comments",
      minWidth: 150,
      filter: false,
      sortable: false,
      cellRenderer: ({ data }) => {
        return (
          <div>
            <OverlayTrigger
              placement="right"
              rootClose={true}
              rootCloseEvent="mousedown"
              overlay={
                <Popover id={`popover-positioned-right`}>
                  <Popover.Header as="h6" className="popover-title-comment">
                    {t("Comments")}
                  </Popover.Header>
                  <Popover.Body className="popover-body-comment">
                    {data.OrderDetails.Comments}
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="success" size="sm" className="mx-3">
                <FaCommentDots />
              </Button>
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      headerName: "Prescription Image",
      field: "Prescription Image URL",
      minWidth: 200,
      cellRenderer: ({ data }) => {
        return (
          <div>
            {data.OrderDetails["Prescription Image URL"] === "NA" ? (
              <div>
                <MdDoNotDisturbAlt className="not-allowed" />
              </div>
            ) : (
              <a
                href={data.OrderDetails["Prescription Image URL"]}
                target="_blank"
                download
                className="btn btn-sm btn-primary download-btn"
              >
                <FaFileDownload />
              </a>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "OrderDetails.OrderID",
      minWidth: 150,
      cellRenderer: ({ data }) => {
        return (
          <div>
            <Button
              variant="warning"
              size="sm"
              className="mx-3"
              onClick={() => {
                handleStatus({
                  id: data.OrderDetails.OrderID,
                });
              }}
            >
              <FaEdit />
            </Button>
            {data.OrderDetails["PDF Download Link"] === "NA" && (
              <Button
                variant="danger"
                size="sm"
                className="mx-3"
                onClick={() => {
                  handleUpload({ id: data.OrderDetails.OrderID });
                }}
              >
                <FaUpload />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const defaultColDef = {
    sortable: false,
    filter: false,
    floatingFilter: false,
    flex: 1,
  };

  return (
    <div className="ordersMobile">
      <div className="ag-theme-alpine orders-table">
        <AgGridReact
          ref={gridRef}
          rowData={orders}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationAutoPageSize={true}
        ></AgGridReact>
        <Update />
        <Upload />
      </div>
    </div>
  );
}
