import React, { useRef, useCallback, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import Moment from "react-moment";
import { saveAs } from "file-saver";
import UrlImageDownloader from "react-url-image-downloader";

import {
  Badge,
  Button,
  InputGroup,
  FormControl,
  Row,
  Col,
  Form,
  Modal,
  Alert,
  ListGroup,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { handleStatusOrderId } from "../../../redux/userSlice";
import { handleUploadResult } from "../../../redux/userSlice";
import Update from "../../update/Update";
import Upload from "../../upload/Upload";
import { useDispatch, useSelector } from "react-redux";
import { GiTestTubes } from "react-icons/gi";
import { FaFileDownload } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaUpload } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import { MdDoNotDisturbAlt } from "react-icons/md";
import { useTranslation } from "react-i18next";
import "./Allorders.css";

export default function Allorders() {
  const gridRef = useRef();
  const [sdate, setSdate] = useState("");
  const [edate, setEdate] = useState("");
  const [gridApi, setGridApi] = useState("");
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleStatus = ({ id, phoneNumber }) => {
    dispatch(handleStatusOrderId({ id, phoneNumber }));
  };
  const handleUpload = ({ id }) => {
    dispatch(handleUploadResult({ id }));
  };
  const dateFilterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      const dateAsString = cellValue;
      if (dateAsString == null) return -1;
      const cellDate = new Date(dateAsString).getTime();
      if (filterLocalDateAtMidnight.getTime() === cellDate) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
  };
  const columnDefs = [
    {
      headerName: "Status",
      field: "Status",
      filter: true,
      minWidth: 200,
      cellRenderer: ({ data }) => {
        return (
          <div className="py-2">
            <h6 className="status-style">
              {data.Status === "New" ? (
                <Badge pill bg="danger">
                  {t("New")}
                </Badge>
              ) : data.Status === "Received" ? (
                <Badge pill bg="success">
                  {t("Received")}
                </Badge>
              ) : data.Status === "Visited" ? (
                <Badge pill bg="warning">
                  {t("Visited")}
                </Badge>
              ) : data.Status === "Delivered" ? (
                <Badge pill bg="primary">
                  {t("Delivered")}
                </Badge>
              ) : data.Status === "Completed" ? (
                <Badge pill bg="dark">
                  {t("Completed")}
                </Badge>
              ) : (
                <Badge pill bg="dark">
                  Cancelled
                </Badge>
              )}
            </h6>
          </div>
        );
      },
    },
    {
      headerName: "Order ID",
      field: "OrderID",
      minWidth: 200,
    },
    {
      headerName: "Tests",
      field: "TestName",
      valueGetter: (params) => {
        const res = params.data.Tests;
        const ress = JSON.stringify(res);
        return ress;
      },
      minWidth: 150,
      sortable: false,
      filter: false,
      cellRenderer: ({ data }) => {
        return (
          <div>
            <OverlayTrigger
              placement="left"
              rootClose={true}
              rootCloseEvent="mousedown"
              overlay={
                <Popover id={`popover-positioned-left`}>
                  <Popover.Header as="h6" className="popover-title">
                    {t("PatientTest")}
                  </Popover.Header>
                  <Popover.Body className="popover-body">
                    <ListGroup as="ol" numbered>
                      {data.Tests.map((test) => {
                        return (
                          <ListGroup.Item
                            as="li"
                            className="items"
                            key={Math.random() * 100}
                          >
                            {test.TestName}
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="info" size="sm">
                <GiTestTubes />
              </Button>
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      headerName: "Phone Number",
      field: "Phone Number",
      minWidth: 250,
    },
    {
      headerName: "Total Price",
      field: "Total Price",
      minWidth: 150,
    },
    {
      headerName: "Created Date",
      field: "CreatedAt",
      minWidth: 250,

      cellRenderer: ({ data }) => {
        return <Moment format="DD/MM/YYYY | HH:mm">{data.CreatedAt}</Moment>;
      },
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
    },
    {
      headerName: "Updated Date",
      field: "UpdatedAt",
      minWidth: 250,
      filter: false,
      floatingFilter: false,
      sortable: false,
      cellRenderer: ({ data }) => {
        return <Moment format="DD/MM/YYYY | HH:mm">{data.UpdatedAt}</Moment>;
      },
    },
    {
      headerName: "Comments",
      field: "Comments",
      minWidth: 150,
      filter: false,
      sortable: false,
      cellRenderer: ({ data }) => {
        return (
          <div>
            <OverlayTrigger
              placement="right"
              rootClose={true}
              rootCloseEvent="mousedown"
              overlay={
                <Popover id={`popover-positioned-right`}>
                  <Popover.Header as="h6" className="popover-title-comment">
                    {t("Comments")}
                  </Popover.Header>
                  <Popover.Body className="popover-body-comment">
                    {data.Comments}
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="success" size="sm" className="mx-3">
                <FaCommentDots />
              </Button>
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      headerName: "Result",
      field: "Report Download Link",
      minWidth: 150,
      filter: false,
      floatingFilter: false,
      sortable: false,
      cellRenderer: ({ data }) => {
        return (
          <div>
            {data["Report Download Link"] === "NA" ? (
              <Button
                variant="danger"
                size="sm"
                className="mx-3"
                onClick={() => {
                  handleUpload({ id: data.OrderID });
                }}
              >
                <FaUpload />
              </Button>
            ) : (
              <MdDoNotDisturbAlt />
            )}
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      field: "Prescription Image URL",
      minWidth: 250,
      filter: false,
      floatingFilter: false,
      sortable: false,
      cellRenderer: ({ data }) => {
        return (
          <div>
            <Button
              variant="warning"
              size="sm"
              className="mx-3"
              onClick={() => {
                handleStatus({
                  id: data.OrderID,
                  phoneNumber: data["Phone Number"],
                });
              }}
            >
              <FaEdit />
            </Button>
            {data["Prescription Image URL"] !== "NA" && (
              // <Button
              //   variant="primary"
              //   size="sm"
              //   className="download-btn"
              //   onClick={() => {
              //     window.location.href = data["Prescription Image URL"];
              //     console.log(window.location);
              //   }}
              // >
              //   <FaFileDownload />
              // </Button>

              <a
                href={data["Prescription Image URL"]}
                target="_blank"
                download
                className="btn btn-sm btn-primary download-btn"
              >
                <FaFileDownload />
              </a>
            )}
          </div>
        );
      },
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params);
    axios
      .post("https://hook.eu1.make.com/awvt5s79ls8mp2365ntiyx0m1l2u6kqu", {
        Search: [
          {
            SearchTable: true,
            Function: "Search",
            TableName: "CheckMEOrder",
            Byfield: "OrderID",
            AllRecords: true,
          },
        ],
      })
      .then((res) => {
        params.api.applyTransaction({ add: res.data });
      });
  }, []);

  const handleFilter = ({ sdate, edate }) => {
    const filterType = () => {
      if (sdate !== "" && edate !== "") {
        return "inRange";
      } else if (sdate !== "") {
        return "greaterThan";
      } else if (edate !== "") {
        return "lessThan";
      }
    };
    const dateFilterComponent = gridApi.api.getFilterInstance("CreatedAt");
    dateFilterComponent.setModel({
      type: filterType(),
      dateFrom: sdate ? sdate : edate,
      dateTo: edate,
    });
    gridApi.api.onFilterChanged();
  };

  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      handleFilter({
        sdate: values.startDate,
        edate: values.endDate,
      });
    },
    validationSchema: yup.object().shape({
      startDate: yup.date().max(yup.ref("endDate"), t("sdate")),
      endDate: yup.date().min(yup.ref("startDate"), t("edate")),
    }),
  });

  const onExportClick = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  return (
    <div className="allorders">
      <Row>
        <Col xs={9} className="my-3">
          <div className="date-filter">
            <Form className="filter-form" onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs={12} md={4}>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text>{t("From")}</InputGroup.Text>
                    <FormControl
                      type="date"
                      aria-label="Small"
                      className="input-text"
                      id="startDate"
                      onChange={formik.handleChange}
                      value={formik.values.startDate}
                    />
                  </InputGroup>
                  <Form.Text className="text-error">
                    {formik.errors.startDate && formik.errors.startDate}
                  </Form.Text>
                </Col>
                <Col xs={12} md={4}>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text>{t("To")}</InputGroup.Text>
                    <FormControl
                      type="date"
                      aria-label="Small"
                      className="input-text"
                      id="endDate"
                      onChange={formik.handleChange}
                      value={formik.values.endDate}
                    />
                  </InputGroup>
                  <Form.Text className="text-error">
                    {formik.errors.endDate && formik.errors.endDate}
                  </Form.Text>
                </Col>
                <Col xs={12} md={4}>
                  <div className="d-grid">
                    <Button variant="success" type="submit" size="sm">
                      {t("CreateDateFilter")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Col xs={3}>
          <div className="d-grid">
            <Button
              variant="success"
              size="sm"
              className="px-4 mt-3"
              onClick={onExportClick}
            >
              Export
            </Button>
          </div>
        </Col>
      </Row>
      <div className="ag-theme-alpine orders-table mb-4">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationAutoPageSize={true}
        ></AgGridReact>
        <Update />
        <Upload />
      </div>
    </div>
  );
}
