import React,{useEffect} from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { MdLanguage } from "react-icons/md";
import { languages } from "../../App";
import i18next from "i18next";
import Cookies from 'js-cookie'
import './Language.css';




export default function Language() {
  const currentLanguageCode = Cookies.get("i18next") || "en";
  const currentLanguage = languages.find(
    (lang) => lang.code === currentLanguageCode
  );

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage]);

  return (
    <div className="Language px-4">
      <div className="language-dropdown">
        <Row>
          <Col className="align-self-start">
            <Dropdown drop="down">
              <Dropdown.Toggle variant="danger" id="dropdown-basic" size="sm">
                <MdLanguage className="icon-style" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="menu-style">
                {languages.map(({ code, name, dir }) => (
                  <Dropdown.Item
                    key={code}
                    onClick={() => {
                      i18next.changeLanguage(code);
                      document.body.dir = dir;
                    }}
                  >
                    {name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </div>
  );
}
